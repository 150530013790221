import * as React from 'react';
import { useState, useRef } from 'react';
import { Range, Direction, getTrackBackground } from 'react-range';
import styled from 'styled-components';

const STEP = 1;
const MIN = 1;
const MAX = 8; 

const Marker = styled.div`
    height: 3px;
    width: 42px;
    backgroundColor: #575757;
    display: flex;
    justifyContent: center;
    alignItems: center;
      .ZoombarMarker {
        transition: .2s all ease-out;
      }
      .ZoombarMarker:hover {
        transform:scale(1.1);
      }
  `

export default function ZoomRange(props){

  let values = props.rangeLevel;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      <Range
        direction={Direction.Up}
        values={values} 
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => props.handleRangeChange(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              // height: index % 2 ? '3px' : '4px',
              height: '2px',
              // width: index % 2 ? '11px' : '16px',
              width: '32px',
              backgroundColor:'#ccc',
                // index * STEP > MAX - values[0] ? '#ccc' : '#ccc'
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              flexGrow: 1,
              width: '36px',
              display: 'flex',
              height: '200px',
              justifyContent: 'center'
            }}
          >
            <div
              ref={props.ref}
              style={{
                width: '6px',
                height: '100%',
                background: getTrackBackground({
                  values: values,
                  colors: ['#ccc', '#ccc'],
                  min: MIN,
                  max: MAX,
                  direction: Direction.Up,
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <Marker
            {...props}
            style={{
              ...props.style,
            }}
          >
            <div
              className="ZoombarMarker"
              style={{
                width: '100%',
                height: '3px',
                // backgroundColor: isDragged ? '#575757' : '#CCC'
                backgroundColor: '#575757'
              }}
            />
          </Marker>
        )}
      />
    </div>
  );
};
