import styled from "styled-components";

import { ReactComponent as NoiseWalls } from './../icons/noise-walls.svg'
import { ReactComponent as RetainingWalls } from './../icons/retaining-walls.svg'
import { ReactComponent as BuildingApplications } from './../icons/buildings-and-rooftops.svg'
import { ReactComponent as SpeechBubble } from './../icons/contact-speech-bubble.svg'
import { ReactComponent as ArrowRight } from './../icons/arrow-right.svg'
import { ReactComponent as ArrowLeft } from './../icons/arrow-left.svg'
import { ReactComponent as ArrowDown } from './../icons/arrow-down.svg'
import { ReactComponent as ZoomIn } from './../icons/zoom-in.svg'
import { ReactComponent as ZoomOut } from './../icons/zoom-out.svg'
import { ReactComponent as Minus } from './../icons/minus.svg'
import { ReactComponent as Plus } from './../icons/plus.svg'
import { ReactComponent as Filter } from './../icons/filter.svg'

export default function Icon(props){

    return (
        <>
            { props.type === "Noise Walls" ? <NoiseWalls/> : null}
            { props.type === "Retaining Walls" ? <RetainingWalls/> : null}
            { props.type === "Building Applications" ? <BuildingApplications/> : null}
            { props.type === "Speech Bubble" ? <SpeechBubble/> : null}
            { props.type === "Arrow Right" ? <ArrowRight/> : null}
            { props.type === "Arrow Left" ? <ArrowLeft/> : null}
            { props.type === "Arrow Down" ? <ArrowDown/> : null }
            { props.type === "Zoom In" ? <ZoomIn/> : null }
            { props.type === "Zoom Out" ? <ZoomOut/> : null }
            { props.type === "Minus" ? <Minus/> : null }
            { props.type === "Plus" ? <Plus/> : null }
            { props.type === "Filter" ? <Filter/> : null }
        </>
    )
}