import styled, { css } from "styled-components";
import Icon from "./Icon";

const SolutionWrapper = styled.div`
    cursor: pointer;
    margin-right: 2rem;
    margin-bottom: 2rem;
    width: calc((100% / 4) - 4rem);
    max-width: 310px;
    min-width: 150px;
    background: #fff;
    transition: 0.6s ease;
    /* Make grey the default and overwrite with colours */
    border-bottom: 7px solid #bfbfbf;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0215656), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0178832), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.015), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00843437);
    
    &:last-child {
        margin-right:0;
    }
    &:hover, &:focus {
        transform: scale(1.03);
    }

    ${props =>
        props.solution === "Noise Walls" &&
        css`
            border-color: var(--green);
            box-shadow: 0px 38px 80px rgba(0, 70, 37, 0.08), 0px 15.8755px 33.4221px rgba(0, 70, 37, 0.0575083), 0px 8.4878px 17.869px rgba(0, 70, 37, 0.0476886), 0px 4.75819px 10.0172px rgba(0, 70, 37, 0.04), 0px 2.52704px 5.32008px rgba(0, 70, 37, 0.0323114), 0px 1.05156px 2.21381px rgba(0, 70, 37, 0.0224916);
        `};
    ${props =>
        props.solution === "Retaining Walls" &&
        css`
            border-color: var(--lightBlue);
            box-shadow: 0px 38px 80px rgba(8, 101, 143, 0.08), 0px 15.8755px 33.4221px rgba(8, 101, 143, 0.0575083), 0px 8.4878px 17.869px rgba(8, 101, 143, 0.0476886), 0px 4.75819px 10.0172px rgba(8, 101, 143, 0.04), 0px 2.52704px 5.32008px rgba(8, 101, 143, 0.0323114), 0px 1.05156px 2.21381px rgba(8, 101, 143, 0.0224916);
        `};
    ${props =>
        props.solution === "Building Applications" &&
        css`
            border-color: var(--darkBlue);
            box-shadow: 0px 38px 80px rgba(3, 0, 70, 0.08), 0px 15.8755px 33.4221px rgba(3, 0, 70, 0.0575083), 0px 8.4878px 17.869px rgba(3, 0, 70, 0.0476886), 0px 4.75819px 10.0172px rgba(3, 0, 70, 0.04), 0px 2.52704px 5.32008px rgba(3, 0, 70, 0.0323114), 0px 1.05156px 2.21381px rgba(3, 0, 70, 0.0224916);
        `};

    @media(max-width: 720px) {
        width: calc((100% / 2) - 4rem);
    }
    @media(max-width:600px) {
        margin-right:1rem;
        margin-bottom:1rem;
    }
    @media(max-width: 415px) {
        margin-top: 2.5rem;
    }
    @media(max-width: 320px) {
        min-width: 132px;
        margin-top: 1.5rem;
    }
`;


const SolutionImage = styled.div`
    img {
        width: 100%;
    }
    @media(max-width:415px) {
        display:none;
    }
`;
const SolutionContent = styled.div`
    position: relative;
`;
const SolutionIcon = styled.div`
    position: absolute;
    top: -4rem;
    right: 2rem;
    width: 5.4rem;
    height: 5.4rem;
    svg {
        padding: 10px;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        path {
            stroke: white;
        }
    }
    ${props =>
        props.solution === "Noise Walls" &&
        css`
            svg {
                background-color: var(--green);
            }
        `};
    ${props =>
        props.solution === "Retaining Walls" &&
        css`
            svg {
                background-color: var(--lightBlue);
            }
        `};
    ${props =>
        props.solution === "Building Applications" &&
        css`
            svg {
                background-color: var(--darkBlue);
            }
        `};
    
    @media(max-width: 1200px) {
        width: 4.5rem;
        height: 4.5rem;
        top: -3.8rem;
        right: 1.2rem;
    }
    @media(max-width: 1024px) {
        width: 4rem;
        height: 4rem;
        top: -3.5rem;
        right: 1rem;
    }
    @media(max-width: 375px) {
        top: -2rem;
        right: 0.5rem;
        width: 3.5rem;
        height: 3.5rem;
        svg {
            padding: 6px;
        }
    }
`;
const TextContainer = styled.div`
    padding: 0 2rem;
`;
const Pretitle = styled.p`
    &&& {
        font-weight: 500;
        margin-bottom: 0rem;
        margin-top: 1rem;

        @media(max-width: 1024px) {
            padding-top: 1rem;
        }
        @media(max-width: 768px) {
            font-size: 1.6rem;
            margin-bottom: 0;
        }
        @media(max-width:375px) {
            margin-top:0;
            font-size: 1.4rem;
        }
    }
`;
const Title = styled.h2`
    &&& {
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 1.2rem;
        font-size: 2.7rem !important;
        @media(max-width: 1000px) {
            font-size: 2rem !important;
        }
        @media(max-width: 450px) {
            font-size: 1.6rem !important;
        }
        color: #000 !important;
        letter-spacing: 0 !important;
        ${props =>
            props.solution === "Noise Walls" &&
            css`
                color: var(--green) !important;
            `};
        ${props =>
            props.solution === "Retaining Walls" &&
            css`
                color: var(--lightBlue) !important;
            `};
        ${props =>
            props.solution === "Building Applications" &&
            css`
                color: var(--darkBlue) !important;
            `};
    
            @media(max-width: 1024px) {
                margin-bottom: 1rem;
            }    
            @media(max-width: 768px) {
                margin-top:0.5rem;
                margin-bottom: 0.5rem;
            }
            @media(max-width:375px) {
                margin-top:0;
                padding-bottom:5px;
            }
    
        }
`;


export default function Solution(props){

    const handlePress = e => {
        if(e.key === 'Enter') { 
            props.setActiveSolution(props.solution)
        }
    }

    return (
        <SolutionWrapper solution={props.solution} onClick={() => props.setActiveSolution(props.solution)} role="button" tabIndex={0} onKeyPress={handlePress}>
            <SolutionImage> <img src={props.imgSrc} alt={props.imgAlt}/> </SolutionImage>
            <SolutionContent>
                <SolutionIcon solution={props.solution}> <Icon type={props.solution}/> </SolutionIcon>
                <TextContainer>
                    <Pretitle>
                        { props.solution === "All" 
                            ? "Start Viewing"
                            : "Solutions for"
                        }
                    </Pretitle>
                    <Title solution={props.solution}>
                        { props.solution === "All" 
                            ? "All Solutions"
                            : props.solution
                        }
                    </Title>
                </TextContainer>
            </SolutionContent>
        </SolutionWrapper>
    )
}