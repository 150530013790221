
import { useState, useEffect, useRef } from 'react';

import styled, { css } from "styled-components";
import Pin from "../components/Pin";
import Filter from "../components/Filter";
import ZoomBar from "../components/ZoomBar";
import Icon from "../components/Icon";
import ProductContainer from "./ProductContainer";
import { Lightbox } from "react-modal-image";
import cityscapeBg from "./../images/cityscape-full.png";
import MobileScrollBution from '../components/MobileScrollButton'; 

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
 

const Wrapper = styled.div`
    width:100vw;
    height:100%;
    ${props =>
        props.activePinID &&
        css`
            .react-transform-wrapper {
                cursor: auto !important;
            }
        `};

    // @media(max-width:845px) {
    //     height:90vh;
    // }
    // @media(max-width:450px) {
    //     height: 85vh;
    // }

    .react-transform-wrapper {
        max-width:100%;
        max-height:100%;
        overflow: hidden;
        cursor: grab;
    }
    
    .react-transform-component {
        will-change:transform;
    }

    .cityscape {
        animation: 1s fade-in;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
        opacity: 0;
        max-width: initial;

        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
`

export default function MapContainer(props){
    
    const transformRef = useRef(null);
    const [ activePinID, setActivePinID ] = useState(null);
    const [ mapIsFrozen, setMapIsFrozen ] = useState(false);
    const [ activeProductImageModal, setActiveProductImageModal ] = useState([]);
    const [ productLightboxIsOpen, setProductLightboxIsOpen ] = useState(false);
    const [ modalSlideOut, setModalSlideOut ] = useState(false);
    const [ prevScale, setPrevScale ] = useState(null);
    const [ prevPos, setPrevPos ] = useState(null);
    const [ isFirstPinClick, setIsFirstPinClick ] = useState(true);
    const [ rangeLevel, setRangeLevel ] = useState([5]); //the range library requires this to be an array



    /**********************  
    ****** FUNCTIONS ******
    **********************/
    
    function logPositionState(){
        if (activePinID === null){
            setPrevPos({'positionX' : transformRef.current.state.positionX, 'positionY' : transformRef.current.state.positionY})
            setPrevScale(transformRef.current.state.scale)
        }
    }

    function zoomToElementId(id){
        // airtable gives id's that are numbers, but the react function requires id's that start with a letter
        // add 'a' before each 
        let updatedId = 'a' + id;
        transformRef.current.zoomToElement(updatedId)

        //there's a bug where sometimes the first, and only the first, pin click won't zoom in
        //wait 20ms, and if there's no movement, re-trigger the zoom
        setTimeout(() => {
            if (transformRef.current.state.scale === prevScale){
                if (isFirstPinClick){
                    zoomToElementId(id)
                    setIsFirstPinClick(false); 
                }
             }
        }, 20);
    }

    function handlePinClick(id, solution){
        logPositionState();
        setActivePinID(id);
        props.setActiveSolution(solution);
        zoomToElementId(id)
        setMapIsFrozen(true)
    }

    function handleProductClose(){
        setModalSlideOut(true);
        setTimeout(() => {
            setActivePinID(null)
            setModalSlideOut(false);
        }, 700)
        setMapIsFrozen(false);
        transformRef.current.setTransform(prevPos.positionX, prevPos.positionY, prevScale);
    }

    function handleProductLightbox(image){
        setProductLightboxIsOpen(true);
        setActiveProductImageModal(image);
    }

    /*************************  
    ****** ZOOM RELATED ******
    *************************/

    //The range slider uses values 1-8
    //The zoom library uses *non-linear* values from 0.2 to 0.78 (the zoom amount is not consistent)
    //Most of this is 'translating' between the two of them 

    function handleRangeChange(rangeScale){

        setRangeLevel(rangeScale);
        let scale = translateRangeLevelIntoScale(rangeScale)
        //https://github.com/prc5/react-zoom-pan-pinch/issues/137
        const targetScale = parseFloat(scale);
        const factor = Math.log(targetScale / transformRef.current.state.scale);
        const { zoomIn, zoomOut } = transformRef.current;
        
        if (targetScale > transformRef.current.state.scale) {
            zoomIn(factor, 200);
        } else {
            zoomOut(-factor, 200);
        }
    }

    function translateRangeLevelIntoScale(rangeLevel){
        switch(rangeLevel[0]){
            case 8 :
                return 0.8;
            case 7 :
                return 0.63;
            case 6 :
                return 0.52;
            case 5 :
                return 0.4;
            case 4 :
                return 0.34;
            case 3 :
                return 0.28;
            case 2 :
                return 0.23;
            case 1 :
                return 0.2;
        
        }
    }

    function handleZoomButtonPress(direction){

        const { zoomIn, zoomOut } = transformRef.current;

        if (direction === 'zoomIn'){
            if (rangeLevel[0] === 8) return;
            setRangeLevel([rangeLevel[0] + 1])
            zoomIn(0.2, 200);
        }
        if (direction === 'zoomOut'){
            if (rangeLevel[0] === 1) return;
            setRangeLevel([rangeLevel[0] - 1])
            zoomOut(0.2, 200);
        }
    }

    return(
        <Wrapper activePinID={activePinID}>
            <TransformWrapper 
                initialScale={0.4}
                minScale={0.2}
                maxScale={0.78}
                centerOnInit={true}
                ref={transformRef}
                panning={{disabled:mapIsFrozen}}
                wheel={{disabled:true}}
                pinch={{disabled:true}}
                doubleClick={{disabled:true}}
                onPanningStop={logPositionState}
                className="transformWrapper"
        
            >
                 {({ state: { scale }, zoomIn, zoomOut, centerView, zoomToElement, resetTransform }) => (
                    <>
                        <TransformComponent>
                        <img src={cityscapeBg} alt="Illustration of a city from above" className='cityscape'/>

                        { props.airtablePins.map((pinData, key) => {
                            let data = pinData.fields;
                            if (data['Sector'] != null){
                                return(
                                    <Pin 
                                        id={data['ID']}
                                        key={key} 
                                        posX={data['Pin X Position']} 
                                        posY={data['Pin Y Position']} 
                                        solution={data['Solution']}
                                        active={activePinID === data['ID']}
                                        activeSolution={data['Solution'] === props.activeSolution || props.activeSolution === 'All'}
                                        activePinID={activePinID}
                                        scaleBase={scale}
                                        handlePinClick={handlePinClick}
                                        modalSlideOut={modalSlideOut}
                                    />
                                )
                            }                    
                        })}
{/* 
                        { pinData.map((data, key) => {
                            return (
                                <Pin key={key} 
                                    id={data['ID']} 
                                    posX={data['Pin X Position']} 
                                    posY={data['Pin Y Position']} 
                                    solution={data['Solution']}
                                    active={activePinID === data['ID']}
                                    activeSolution={data['Solution'] === props.activeSolution || props.activeSolution === 'All'}
                                    activePinID={activePinID}
                                    scaleBase={scale}
                                    handlePinClick={handlePinClick}
                                    modalSlideOut={modalSlideOut}
                                    airtablePins={props.airtablePins}
                                />
                            );
                        })} */}

                        </TransformComponent>

                        <ZoomBar rangeLevel={rangeLevel} handleRangeChange={handleRangeChange} zoomIn={()=>handleZoomButtonPress('zoomIn')} zoomOut={()=>handleZoomButtonPress('zoomOut')} activePinID={activePinID} />
                       
                    </>
                    )}
            </TransformWrapper>


            <Filter activeSolution={props.activeSolution} setActiveSolution={props.setActiveSolution} activePinID={activePinID} />
            
            { activePinID
                ? <ProductContainer 
                    activePinID={activePinID} 
                    setActivePinID={setActivePinID} 
                    zoomToElementId={zoomToElementId} 
                    setMapIsFrozen={setMapIsFrozen} 
                    handleProductClose={handleProductClose} 
                    modalSlideOut={modalSlideOut} 
                    handleProductLightbox={handleProductLightbox}
                    airtablePins={props.airtablePins}
                    />
                : null
            }
  
            {  productLightboxIsOpen && activeProductImageModal
                ?  <Lightbox
                        large={activeProductImageModal}
                        onClose={() => setProductLightboxIsOpen(false)}
                        hideDownload={true}
                    />
                : null
            }

            <MobileScrollBution activePinID={activePinID} />

        </Wrapper>
    )
}