import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import barlow400 from './../fonts/barlow-v11-latin-regular.woff';
import barlow500 from './../fonts/barlow-v11-latin-500.woff';
import barlow600 from './../fonts/barlow-v11-latin-600.woff';
import barlow700 from './../fonts/barlow-v11-latin-700.woff';

const GlobalStyles = createGlobalStyle`

    /* barlow-regular - latin */
    @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        src: url(${barlow400}) format('woff')
    }
    /* barlow-500 - latin */
    @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        src: url(${barlow500}) format('woff')
    }
    /* barlow-600 - latin */
    @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        src: url(${barlow600}) format('woff')
    }
    /* barlow-700 - latin */
    @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        src: url(${barlow700}) format('woff')
    }
	html{
		--green:#007A40;
		--lightBlue:#0098C8;
        --darkBlue:#004BBB;
		--grey:#BFBFBF;

        /* Active Color gets updated in App.js on render */
        --activeColor:#BFBFBF;
       
		box-sizing:border-box;
		font-size: 100%;
	}
	*, *:before, *:after{
		box-sizing:inherit;
	}

    /* to mimic Durisol live site, set root of 10px */
    html {
        font-size:10px;
        --cityscape-h1: 6.4rem;
        --cityscape-h2: 2.7rem;
        --cityscape-p: 2.4rem;

        @media(max-width: 1440px) {
            --cityscape-h1: 5rem;
            --cityscape-p: 2rem;
        }
        @media(max-width: 1024px) {
            --cityscape-h2: 2rem;
        }
        @media(max-width: 768px) {
            --cityscape-h1: 4.4rem;
            --cityscape-h2: 1.8rem;
            // --cityscape-p: 2rem;
        }
        @media(max-width: 600px) {
            --cityscape-p: 1.6rem;
        }
        @media(max-width: 415px) {
            --cityscape-h1: 3rem;
        }
        @media(max-width: 375px) {
            --cityscape-h1: 2.5rem;
        }
        @media(max-width: 320px) {
            --cityscape-h1: 2rem;
            --cityscape-p: 1.6rem;
        }
    }
	body {
		font-family:'Barlow', sans-serif;
		padding:0;
		margin:0;
		font-size:18px;
	}
    .sr-only { position: absolute; width: 1px; height: 1px; margin: -1px; border: 0; padding: 0; white-space: nowrap; clip-path: inset(100%); clip: rect(0 0 0 0); overflow: hidden;}

    .__react_modal_image__modal_container {
        top:100px !important;
        height:calc(100% - 100px) !important;
    }

    body #root-cityscape {
        // 100vh also given in beaverbuilder so that it takes space before css loads in
        height:100vh; 
        padding-top:100px;
        overflow:hidden;
        @media(max-width:1100px) {
            padding-top:0px;
            height:90vh;
        }
        @media(max-width:768px){
            #root-cityscape {
                height:90vh;
            }
        }
        @media(max-width:450px) {
            height:80vh;
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(.6);
            opacity: 0;
        }
        30%{
            opacity: 0.9;
        } 
        60% { 
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes outer-pulse {
        0% {
            transform: scale(.4);
            opacity: 0;
        }
        30%{
            opacity: 0.4;
        } 
        60% {
            opacity: 0;
            transform: scale(1);
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes radiate {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    @keyframes radiate-fade-in {
        from {
            opacity: 0;
            transform: scale(0);
        }
        to {
            opacity: 0.5;
            transform: scale(1);
        }
    }
`;

export default GlobalStyles;