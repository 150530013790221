import styled, { css } from "styled-components";
import Icon from "./Icon";
import { useState, useEffect } from "react";
import ModalImage from "react-modal-image";

const Wrapper = styled.div`
    color: var(--activeColor);
    display:none;
    position: relative;
    ${props =>
        props.active &&
        css`
          display:block;
          width: 90%;
          margin: 5% auto;
          margin-top:70px;
          @media(max-width:835px){
              margin-top: 2rem;
          }
        `};
`

const CloseButton = styled.button`
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f4f4f4;
        &:hover {
            background: #d0d0d7;
        }

        svg {
            width: 3rem;
        }

        @media(max-width: 834px) {
            background: var(--activeColor);
            position: sticky;
            top: 2.2rem;
            float: right;
            width: 5rem;
            height: 5rem;
            svg {
                width: 2.5rem;
                path {
                    fill: white;
                }
            }

            &:hover {
                background: #575757;
            }
        }
        @media(max-width: 375px) {
            top: 2rem;
        }
    `

const NavigateSolutions = styled.div`
        display:flex;
        align-items: center;
`

const NavigateSolutionsButton = styled.div`
        background:#f4f4f4;
        padding:1rem;
        width: 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        &:hover {
            background: #d0d0d7;
        }
        
        svg {
            width: 4rem;
        }

        @media(max-width: 450px) {
            width: 5rem;
            height: 5rem;
            svg {
                width: 3rem;
            }
        }
`

const NavigateSolutionsText = styled.div`
        color: #575757;
        font-size: 2.4rem;
        font-weight: 700;
        margin: 0 1rem;
        @media(max-width: 450px) {
            font-size: 2rem;
        }
`

const SectorContainer = styled.div`
        margin-top: 5rem;
        display: flex;
        align-items: center;
`

const IconContainer = styled.span`
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    svg {
        width: 100%;
        > * {
            fill: var(--activeColor); 
        }
    }
`

const Sector = styled.div`
        color: var(--activeColor);
        margin-left: 1rem;
        font-size: 2.4rem;
        span {
            font-weight: 700;
        }

        @media(max-width: 450px) {
            font-size: 2rem;
        }
    `

const ProductInfoContainer = styled.div`
        color: #575757;
        padding-bottom:4rem;
        h2 {
            color: #000;
            font-size: 6rem;
            margin: 1.5rem 0;
        }
        .description {
            line-height: 1.62;
            font-size: 2rem;
            font-weight: 500;
        }

        @media(max-width: 450px) {
            h2 {
                font-size: 3.5rem;
            }
            .description {
                font-size: 1.6rem;
            }
        }
`

const ProductInfoTitle = styled.h2`
        &&&& {
            letter-spacing: 0;
            font-size:5rem;
            margin-bottom:2rem;
        }
`

const ButtonsContainer = styled.div`
        display: flex;
        align-items: center;
        margin-top: 2rem;

        &&& {
            .project-info-button {
                line-height: 1.2 !important;
                letter-spacing: 0 !important;
            }
        }

        .project-info-button {
            padding: 1rem 2rem;
            margin-right: 1.5rem;
            text-decoration: none;
            font-weight: 700;
            font-size: 1.7rem;
            display: flex;
            align-items: center;
            transition: 0.3s ease-in-out;
            span {
                margin-left: 1rem;
                width: 3.2rem;
                height: 3.2rem;
                min-width: 2.3rem;
                display:flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 100%;
                }
            }

            @media (max-width: 1500px) {
                margin-bottom: 2rem;
            }
        }

        .btn__more-about-project {
            background: var(--activeColor);
            color: #fff;

            svg path {
                fill: #fff;
            }

            &:hover {
                background: #575757;
            }
        }

        .btn__get-started, .btn__see-projects {
            color: #575757;
            background: #f4f4f4;

            span {
                svg path {
                    fill: #575757;
                }
            }

            &:hover {
                background: #d0d0d7;
            }
        }

        .btn__get-started {
            span {
                margin-right: 1rem;
                margin-left: 0;
            }
        }

        @media(max-width: 1500px) {
            flex-direction: column;
            align-items: flex-start;
        }
`

const Accordion = styled.div`
        margin-bottom: 2rem;
        margin-top: 3rem;

        @media(max-width:450px) {
            margin-top: 0;
        }
`

const AccordionHeader = styled.h3`
        margin: 0;
        font-size: 2.5rem;

        @media(max-width: 450px) {
            font-size: 2rem;
        }
`

const AccordionButton = styled.div`
        cursor: pointer;
        display: flex;
        align-items: center;
        &&& {
            color: #575757 !important;
            font-weight: 700 !important;
            font-size: 2.5rem !important;
            letter-spacing: 0 !important;
        }
        ${props =>
            props.isOpen &&
            css`
                &&& {
                    color: var(--activeColor) !important;
                }

                span {
                    svg path {
                        fill: var(--activeColor);
                    }
                }
            `};

        span {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            max-width: 3rem;
            svg {
                width:100%;
            }
        }

        @media(max-width: 450px) {
            span {
                width: 2rem;
            }
        }
`

const AccordionCollapse = styled.div`
    max-height: 0;
    overflow:hidden;
    // specific cubic-bezier to fix max-height transition delay
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
    ${props =>
        props.isOpen &&
        css`
            max-height: 100rem;
            transition: max-height 0.8s ease-in-out;
        `};
`

const AccordionContent = styled.div`
        font-size: 1.8rem;
        font-weight: 500;

        @media(max-width: 450px) {
            font-size: 1.6rem;
        }
`

const List = styled.ul`
        margin-top: 2.5rem;
        list-style-type:none;
`

const ListItem = styled.li`
        display:flex;
        line-height: 1.2;
        margin-bottom: 3rem;

        @media(max-width:450px) {
            margin-bottom: 2rem;
        }
`

const Diamond = styled.div`
        margin-right:1rem;
        svg rect {
            stroke: var(--activeColor);
        }
`

const ProjectImagesContainer = styled.div`
        margin-top: 7.5rem;
        padding: 3rem 0;
        padding-right:2rem;
        position: relative;
        width: 100%;
        min-height:270px;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -5%;
            z-index: -1;
            display:block;
            height: 100%;
            width: 105%;
            background: #FAFAFA;
        }

        h3 {
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 3rem;
            padding-right: 2rem;
        }

        @media(max-width:450px) {
            margin-top: 4rem;
        }
`

const ProjectImages = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const ImageWrapper = styled.div`
        width:135px;
        height:135px;
        margin-right: 2rem;
        margin-bottom: 2rem;
        position: relative;
        &:last-child {
            margin-right: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &:hover {
            cursor:pointer;
        }
        .zoom-icon {
            pointer-events: none;
            background: #fff;
            position: absolute;
            bottom: 5px;
            left: 5px;
            padding: 5px;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 100%;
                max-width: 1.5rem;
            }
        }

        .__react_modal_image__header {
            justify-content: flex-end;
        }

        animation: 1.8s fade-in;
        animation-fill-mode: forwards;
        opacity: 0;

        @media(max-width: 450px) {
            width: calc((100% / 3) - 1rem);
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
`

const ImageContainer = styled.div`
        width:100%;
        height: 100%;
        aspect-ratio: 1;
        display: flex;
        align-items: stretch;
        justify-content: center;
        div {
            display: flex;
        }
`

const ProjectImagesTitle = styled.h3`
    && {
        color:#575757; 
    }
        
`


export default function Product(props){

    const [ benefitOneIsOpen, setBenefitOneIsOpen ] = useState(true);
    const [ benefitTwoIsOpen, setBenefitTwoIsOpen ] = useState(false);
    const [ benefitThreeIsOpen, setBenefitThreeIsOpen ] = useState(false);
    const [ benefitFourIsOpen, setBenefitFourIsOpen ] = useState(false);
    const [ photos, setPhotos ] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPhotos(props.photos)
        }, 1000);
        return () => clearTimeout(timer);
    }, [])

    function mapBenefit(benefitFromProps){
        if (benefitFromProps){
            let array = benefitFromProps
            .split("- ")
            .filter(x => x)
            .map( (benefit, key) => {
                return (
                    <ListItem key={key}>
                        <Diamond>                        
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="7.25" y="1.41421" width="8.25305" height="8.25305" transform="rotate(45 7.25 1.41421)" stroke="#575757" strokeWidth="2"/></svg>
                        </Diamond>
                        {benefit}
                    </ListItem>
                )
            })
            return <List>{array}</List>;
        } else {
            return null;
        }
    }

    let benefitOneItems, benefitTwoItems, benefitThreeItems, benefitFourItems;

    if (props.benefitOne){
        benefitOneItems = mapBenefit(props.benefitOne.data);
    }
    if (props.benefitTwo){
        benefitTwoItems = mapBenefit(props.benefitTwo.data);
    }
    if (props.benefitThree){
        benefitThreeItems = mapBenefit(props.benefitThree.data);
    }
    if (props.benefitFour){
        benefitFourItems = mapBenefit(props.benefitFour.data);
    }

    let relatedSolutions = props.relatedSolutions;
    let solutionPosition, solutionTotal, prevSolution, nextSolution = null;

    if (relatedSolutions){
        solutionPosition = relatedSolutions.findIndex(pin => {
            return pin.fields["ID"] === props.id;
          });

        solutionTotal = relatedSolutions.length;

        let prevSolutionPos = solutionPosition - 1 > -1 ? solutionPosition - 1 : solutionTotal - 1;
        prevSolution = relatedSolutions[prevSolutionPos]['fields']["ID"];

        let nextSolutionPos = solutionPosition + 1 < solutionTotal ? solutionPosition + 1 : 0;
        nextSolution = relatedSolutions[nextSolutionPos]['fields']["ID"];
    }


    // function prepImages(){
      
    //     var base = props.projectPhotos;
    //     var reBrackets = /\((.*?)\)/g;
    //     var listOfImages = [];
    //     var found;
    //     while(found = reBrackets.exec(base)) {
    //         listOfImages.push(found[1]);
    //     };
    //     return listOfImages;
    // }

    // let imagesArray = prepImages()


    let sectorsCleaned = "";

    props.sector.forEach( (sector, i) => {
        if (props.sector.length != i + 1){
            sectorsCleaned += sector+ ", ";
        } else {
            sectorsCleaned += sector;
        }
    })

    const handlePressPrevious = e => {
        if(e.key === 'Enter') { 
            props.setActivePinID(prevSolution); 
            props.zoomToElementId(prevSolution);
        }
    }

    const handlePressNext = e => {
        if(e.key === 'Enter') { 
            props.setActivePinID(nextSolution); 
            props.zoomToElementId(nextSolution);
        }
    }

    const handlePressBenefitOne = e => {
        if(e.key === 'Enter') { 
            setBenefitOneIsOpen(!benefitOneIsOpen)
        }
    }

    const handlePressBenefitTwo = e => {
        if(e.key === 'Enter') { 
            setBenefitTwoIsOpen(!benefitTwoIsOpen)
        }
    }

    const handlePressBenefitThree = e => {
        if(e.key === 'Enter') { 
            setBenefitThreeIsOpen(!benefitThreeIsOpen)
        }
    }

    const handlePressBenefitFour = e => {
        if(e.key === 'Enter') { 
            setBenefitFourIsOpen(!benefitFourIsOpen)
        }
    }

    const handleImagePress = (e, img) => {
        if(e.key === 'Enter') { 
            props.handleProductLightbox(img)
        }
    }

    return(
        <Wrapper active={props.active}>
            <CloseButton onClick={()=>props.handleProductClose()}>
                <span className="sr-only">Close product modal</span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.2501 15L25.9938 5.22502C26.1555 5.05926 26.2446 4.83608 26.2417 4.60459C26.2387 4.3731 26.144 4.15226 25.9782 3.99064C25.8125 3.82903 25.5893 3.73988 25.3578 3.74281C25.1263 3.74574 24.9055 3.84051 24.7438 4.00627L15.0001 13.75L5.22509 4.00627C5.06136 3.85535 4.8456 3.7736 4.62297 3.77812C4.40034 3.78265 4.18809 3.87311 4.03064 4.03057C3.87318 4.18802 3.78272 4.40027 3.77819 4.6229C3.77366 4.84553 3.85541 5.06129 4.00634 5.22502L13.7501 15L4.00634 24.775C3.91998 24.8546 3.85105 24.9513 3.80391 25.0588C3.75676 25.1664 3.73242 25.2826 3.73242 25.4C3.73242 25.5175 3.75676 25.6336 3.80391 25.7412C3.85105 25.8488 3.91998 25.9454 4.00634 26.025C4.08555 26.1065 4.18029 26.1712 4.28496 26.2154C4.38962 26.2596 4.50209 26.2824 4.61571 26.2824C4.72933 26.2824 4.8418 26.2596 4.94647 26.2154C5.05113 26.1712 5.14587 26.1065 5.22509 26.025L15.0001 16.25L24.7751 25.9938C24.8547 26.0801 24.9513 26.149 25.0589 26.1962C25.1665 26.2433 25.2826 26.2677 25.4001 26.2677C25.5175 26.2677 25.6337 26.2433 25.7413 26.1962C25.8489 26.149 25.9455 26.0801 26.0251 25.9938C26.1114 25.9142 26.1804 25.8175 26.2275 25.71C26.2747 25.6024 26.299 25.4862 26.299 25.3688C26.299 25.2513 26.2747 25.1351 26.2275 25.0276C26.1804 24.92 26.1114 24.8234 26.0251 24.7438L16.2501 15Z" fill="#575757"/></svg>
            </CloseButton>

            <NavigateSolutions>
                <NavigateSolutionsButton onClick={()=> {props.setActivePinID(prevSolution); props.zoomToElementId(prevSolution)}} role="button" tabIndex={0} onKeyPress={handlePressPrevious}>
                    <span className="sr-only">View previous product</span>
                    <Icon type="Arrow Left" />
                </NavigateSolutionsButton>
                <NavigateSolutionsText>{ solutionPosition + 1} / {solutionTotal}</NavigateSolutionsText>
                <NavigateSolutionsButton onClick={()=> {props.setActivePinID(nextSolution); props.zoomToElementId(nextSolution)}} role="button" tabIndex={0} onKeyPress={handlePressNext}>
                    <span className="sr-only">View next product</span>
                    <Icon type="Arrow Right" />
                </NavigateSolutionsButton>
            </NavigateSolutions>
            <SectorContainer>
                <IconContainer> <Icon type={props.solution} /> </IconContainer>
                <Sector><span>{props.solution}</span> for <span>{sectorsCleaned}</span></Sector>
            </SectorContainer>
            <ProductInfoContainer>
                <ProductInfoTitle>{props.title}</ProductInfoTitle>
                <div className="description">{props.description}</div>
                <ButtonsContainer>
                    <a target="_blank" href={props.linkToProduct} className="project-info-button btn__more-about-project" target="_blank">
                        More about this product 
                        <span><Icon type="Arrow Right"/></span>
                    </a>
                    <a target="_blank" href="/contact-us" className="project-info-button btn__get-started">
                        <span><Icon type="Speech Bubble"/></span>
                        Get Started
                    </a>
                    <a target="_blank" href={`/our-work?filter=${props.solution}`} className="project-info-button btn__see-projects">
                        See Projects 
                        <span><Icon type="Arrow Right"/></span>
                    </a>
                </ButtonsContainer>
                <br />
                { props.benefitOne 
                    ? <Accordion>
                        <AccordionHeader id="accordion1">
                            <AccordionButton onClick={()=>setBenefitOneIsOpen(!benefitOneIsOpen)} isOpen={benefitOneIsOpen} aria-expanded={`${benefitOneIsOpen}`} role="button" tabIndex={0} onKeyPress={handlePressBenefitOne}>
                                <span className="sr-only">Toggle Accordion </span>
                                <span>
                                    {benefitOneIsOpen 
                                        ? 
                                        <Icon type="Minus"></Icon>
                                        : 
                                        <Icon type="Plus"></Icon> }
                                </span>
                                {props.benefitOne.title}
                            </AccordionButton>
                        </AccordionHeader>
                        <AccordionCollapse isOpen={benefitOneIsOpen} aria-labelledby="accordion1">
                            <AccordionContent>
                                { benefitOneItems }
                            </AccordionContent>
                        </AccordionCollapse>
                    </Accordion>
                    : null
                }
                { props.benefitTwo 
                    ? 
                    <Accordion>
                        <AccordionHeader id="accordion2">
                            <AccordionButton onClick={()=>setBenefitTwoIsOpen(!benefitTwoIsOpen)} isOpen={benefitTwoIsOpen} aria-expanded={`${benefitTwoIsOpen}`} role="button" tabIndex={0} onKeyPress={handlePressBenefitTwo}>
                                <span className="sr-only">Toggle Accordion </span>
                                <span>
                                    {benefitTwoIsOpen 
                                        ? 
                                        <Icon type="Minus"></Icon>
                                        : 
                                        <Icon type="Plus"></Icon> }
                                </span>
                                {props.benefitTwo.title}
                            </AccordionButton>
                        </AccordionHeader>
                        <AccordionCollapse isOpen={benefitTwoIsOpen} aria-labelledby="accordion2">
                            <AccordionContent>
                                { benefitTwoItems }
                            </AccordionContent>
                        </AccordionCollapse>
                    </Accordion>
                    : null
                }
                { props.benefitThree 
                    ? 
                    <Accordion>
                        <AccordionHeader id="accordion2">
                            <AccordionButton onClick={()=>setBenefitThreeIsOpen(!benefitThreeIsOpen)} isOpen={benefitThreeIsOpen} aria-expanded={`${benefitThreeIsOpen}`} role="button" tabIndex={0} onKeyPress={handlePressBenefitThree}>
                                <span className="sr-only">Toggle Accordion </span>
                                <span>
                                    {benefitThreeIsOpen 
                                        ? 
                                        <Icon type="Minus"></Icon>
                                        : 
                                        <Icon type="Plus"></Icon> }
                                </span>
                                {props.benefitThree.title}
                            </AccordionButton>
                        </AccordionHeader>
                        <AccordionCollapse isOpen={benefitThreeIsOpen} aria-labelledby="accordion2">
                            <AccordionContent>
                                { benefitThreeItems }
                            </AccordionContent>
                        </AccordionCollapse>
                    </Accordion>
                    : null
                }
                { props.benefitFour 
                    ? 
                    <Accordion>
                        <AccordionHeader id="accordion2">
                            <AccordionButton onClick={()=>setBenefitFourIsOpen(!benefitFourIsOpen)} isOpen={benefitFourIsOpen} aria-expanded={`${benefitFourIsOpen}`} role="button" tabIndex={0} onKeyPress={handlePressBenefitFour}>
                                <span className="sr-only">Toggle Accordion </span>
                                <span>
                                    {benefitFourIsOpen 
                                        ? 
                                        <Icon type="Minus"></Icon>
                                        : 
                                        <Icon type="Plus"></Icon> }
                                </span>
                                {props.benefitFour.title}
                            </AccordionButton>
                        </AccordionHeader>
                        <AccordionCollapse isOpen={benefitFourIsOpen} aria-labelledby="accordion2">
                            <AccordionContent>
                                { benefitFourItems }
                            </AccordionContent>
                        </AccordionCollapse>
                    </Accordion>
                    : null
                }

                {props.photos.length > 0
                ?
                <ProjectImagesContainer>
                    <ProjectImagesTitle>Project Photos</ProjectImagesTitle>
                    {
                        photos.length > 0 
                        ?
                        <ProjectImages>
                            { photos.map( image => {
                                return (
                                    <ImageWrapper key={image.id}>
                                        <div className="zoom-icon"><Icon type="Zoom In"/></div>
                                        { image && image["thumbnails"] 
                                         ? <ImageContainer onClick={()=>props.handleProductLightbox(`/wp-content/react/cityscape-interactive/project-images/full/${props.title}/${image.filename}`)} role="button" tabIndex={0} onKeyPress={(e)=>handleImagePress(e, image["thumbnails"]["full"].url)}>
                                                <span className="sr-only">Expand image of </span>
                                                {/* <img src={image["thumbnails"]["large"].url} alt={image['filename']}/> */}
                                                {/* wp-content/react/cityscape-interactive/project-images */}
                                                <img src={`/wp-content/react/cityscape-interactive/project-images/thumbnail/${props.title}/${image.filename}`} alt={image['filename']}/>
                                            </ImageContainer>
                                         : null
                                        }
                                        
                                    </ImageWrapper>
                                )
                            })}
                        </ProjectImages>
                        : null
                    }
                    
                </ProjectImagesContainer>
                : null
                }
            </ProductInfoContainer>
     
          
    
        </Wrapper>
    )
}