import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import FilterButton from './FilterButton'
import Icon from './Icon'

const FilterWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
    z-index:1;
    min-width: max-content;

    @keyframes slideup {
        from {
            transform: translateY(50%);
            opacity: 0;
        }
        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @media(max-width:845px) {
        left:4%;
        bottom: 2%;
        transform: translateX(0) translateY(0%);
    }
    @media(max-width: 450px) {
        top: 12.5rem;
        bottom: auto;
        right: 7rem;
        left: auto;

        @keyframes slideup {
            from {
                transform: translateY(0%) translateX(30%);
                opacity: 0;
            }
            to {
                transform: translateY(0%) translateX(0%);
                opacity: 1;
            }
        }
    }
`
const FilterBarInner = styled.div`
    background: #fff;
    padding:1.5rem 2rem;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    box-shadow: 0px 51px 80px rgba(0, 0, 0, 0.07), 0px 21.3066px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 11.3915px 17.869px rgba(0, 0, 0, 0.0417275), 0px 6.38599px 10.0172px rgba(0, 0, 0, 0.035), 0px 3.39155px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 1.4113px 2.21381px rgba(0, 0, 0, 0.0196802);
    animation: 0.5s slideup;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;
    ${props =>
        props.activePinID &&
        css`
            animation: 0.8s slidedown;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
            opacity: 1;
            transform: translateY(0%) translateX(-50%);
            
            @keyframes slidedown {
                0% {
                    transform: translateY(0%);
                    opacity: 1;
                    visibility: visible;
                }
                100% {
                    transform: translateY(50%);
                    opacity: 0;
                    visibility: hidden;
                }
            }

            @media(max-width: 845px) {
                @keyframes slidedown {
                    0% {
                        transform: translateX(0%) translateY(0%);
                        opacity: 1;
                        visibility: visible;
                    }
                    100% {
                        transform: translateX(0%) translateY(50%);
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            @media(max-width: 450px) {
                animation-duration: 0.8s;
                @keyframes slidedown {
                    from {
                        transform: translateY(0%) translateX(0%);
                        opacity: 1;
                        visibility: visible;
                    }
                    to {
                        transform: translateY(0%) translateX(30%);
                        opacity: 0;
                        pointer-events: none;
                        visibility: hidden;
                    }
                }
            }
        `};
        
        @media(max-width:1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
        @media(max-width: 845px) {
            min-width: 0;
            @keyframes slideup {
                from {
                    transform: translateX(0%) translateY(50%);
                    opacity: 0;
                }
                to {
                    transform: translateX(0%) translateY(0%);
                    opacity: 1;
                }
            }
        }
        @media(max-width: 450px) {
            max-width: 22rem;
            padding: 0 1rem;
        }
    `
 
const FilterLabel = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    margin-right: 2rem;
    padding-left: 2rem;

    @media(max-width:1024px) {
        padding-left: 0;
        margin-bottom: 1rem;
    }
    @media(max-width: 845px) {
        width:100%;
        justify-content: space-between;
        margin-bottom: 0;
        transition: margin 0.3s ease;

        &.expanded {
            margin-bottom: 1rem;
        }
    }
    @media(max-width: 450px) {
        // font-size: 1.6rem;
        width: auto;
    }
`
const FilterLabelTitle = styled.span`
    &.mobile-title {
        display: none;
        width: 100%;
        cursor: pointer;
        padding: 1rem 0;
        @media(max-width: 845px) {
            display:inline-block;
        }
        @media(max-width: 450px) {
            // padding: 0;
            display: none;
        }
    }
    &.desktop-title {
        @media(max-width: 845px) {
            display: none;
        }
    }
`
const FilterExpand = styled.button`
    display: none;
    width: 4rem;
    position:relative;
    top: 3px;
    border: none;
    background: #5b5b5b;
    cursor: pointer;
    .icon-arrow-down, icon-filter {
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-height: 2.7rem;
    }
    .icon-filter {
        display: none;
    }
    svg {
        width: 100%;
        min-width: 26px;
        min-height: 26px;
        fill: #fff;
        transition: 0.3s ease;
    }

    &.expanded svg {
        transform: rotate(180deg);
    }

    @media(max-width: 845px) {
        display: block;
    }
    @media(max-width: 450px) {
        position: absolute;
        top: 0;
        right: -6rem;
        background: #fff;
        box-shadow: 0px 51px 80px rgba(0,0,0,0.07),0px 21.3066px 33.4221px rgba(0,0,0,0.0503198),0px 11.3915px 17.869px rgba(0,0,0,0.0417275),0px 6.38599px 10.0172px rgba(0,0,0,0.035),0px 3.39155px 5.32008px rgba(0,0,0,0.0282725),0px 1.4113px 2.21381px rgba(0,0,0,0.0196802);
        width: 5.8rem;
        height: 4.5rem;
        svg {
            fill: #575757;
        }
        &.expanded {
            background: var(--grey);
        }
        &.expanded svg {
            transform: rotate(0deg);
            // fill: #fff;
        }
        .icon-arrow-down {
            display: none;
        }
        .icon-filter {
            display: block;
            max-height: 2.3rem;
            svg {
                width: 2.3rem;
                height: 2.3rem;
                min-width: 2.3rem;
                min-height: 2.3rem;
            }
        }
    }
`
const FiltersContainer = styled.div`
    display:flex;

    @media(max-width: 845px) {
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s cubic-bezier(0, 0.1, 0.2, 1.2);
        &.expanded {
            max-height: 30rem;
            transition: 0.8s ease;
        }
    }
    @media(max-width: 450px) {
        max-width: 18rem;
        transition: max-height .5s cubic-bezier(0, 0.1, 0.2, 1.2);
    }
`

export default function Filter(props){

    const [filterBarExpanded, setFilterBarExpanded] = useState(false)

    return(
        <FilterWrapper activePinID={props.activePinID}>
            <FilterBarInner activePinID={props.activePinID}>
            <FilterLabel className={`${filterBarExpanded ? 'expanded' : ''}`}>
                <FilterExpand onClick={() => {filterBarExpanded ? setFilterBarExpanded(false) : setFilterBarExpanded(true)}} className={`${filterBarExpanded ? 'expanded' : ''}`}> 
                    {filterBarExpanded
                        ? <span className='sr-only'>Collapse filter menu</span>
                        : <span className='sr-only'>Expand filter menu</span>
                    }
                    <span className="icon-arrow-down"><Icon type="Arrow Down" /> </span>
                    <span className="icon-filter"><Icon type="Filter" /></span>
                </FilterExpand>   
                <FilterLabelTitle className="desktop-title">Filter by Product</FilterLabelTitle>
                <FilterLabelTitle className="mobile-title" onClick={() => {filterBarExpanded ? setFilterBarExpanded(false) : setFilterBarExpanded(true)}}>Filter by Product</FilterLabelTitle>
            </FilterLabel>
            <FiltersContainer className={`${filterBarExpanded ? 'expanded' : ''}`}>
                <FilterButton solution="All" activeSolution={props.activeSolution} setActiveSolution={props.setActiveSolution} />
                <FilterButton solution="Noise Walls" activeSolution={props.activeSolution} setActiveSolution={props.setActiveSolution} />
                <FilterButton solution="Retaining Walls"  activeSolution={props.activeSolution} setActiveSolution={props.setActiveSolution} />
                <FilterButton solution="Building Applications"  activeSolution={props.activeSolution} setActiveSolution={props.setActiveSolution} />
            </FiltersContainer>
            </FilterBarInner>
        </FilterWrapper>
    )
}