import { useState, useEffect } from 'react';
import IntroContainer from './containers/IntroContainer';
import MapContainer from './containers/MapContainer';
import styled from 'styled-components';
import WrapperStyles from './styles/WrapperStyles';
import GlobalStyles from './styles/GlobalStyles';

const AppContainer = styled.div`
  position:relative;
  height:100%;
  width:100%;
  overflow: hidden;
`

function App() {
 
  const [activeSolution, setActiveSolution] = useState('');
  const [ airtablePins, setAirtablePins ] = useState([]);

  useEffect(() => {
    let activeColor = '#BFBFBF';
    if (activeSolution === 'Noise Walls'){ 
      activeColor = '#007A40';
    }
    if (activeSolution === 'Retaining Walls'){
      activeColor = '#0098C8';
    }
    if (activeSolution === 'Building Applications'){
      activeColor = '#004BBB';
    }
    document.documentElement.style.setProperty(
      '--activeColor',
      activeColor
    );
  }, [activeSolution]);

  //Pin data will not come through in this repo. Requires WP. 
  //Ask Ben for a variation of this to use for testing.

  useEffect(() => {
    if ( typeof window.cityscapeData !== 'undefined' ){
      setAirtablePins(window.cityscapeData.records);
    } else {
      console.warn("Cityscape data was not found. Issue getting from WP / Airtable.")
    }
  }, []);


  return (
    <AppContainer>
      <GlobalStyles /> 
      <WrapperStyles>
        { activeSolution === '' 
          ? <IntroContainer setActiveSolution={setActiveSolution} />
          : <MapContainer activeSolution={activeSolution} setActiveSolution={setActiveSolution} airtablePins={airtablePins} /> 
        }
      </WrapperStyles>
    </AppContainer>
  );
}

export default App; 
