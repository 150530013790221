import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Product from "../components/Product";

    const Modal = styled.div`
        position:absolute;
        z-index:2;
        background:white;
        height:100vh;
        width: 40%;
        top:0;
        left:0;
        box-shadow: 7px 8px 34px rgba(0, 0, 0, 0.15);
        overflow: auto;
        transform: translateX(-100%);
        animation: 0.8s slide;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        @keyframes slide {
            from {
                transform: translateX(-100%);
            }
            to {
                transform: translateX(0%);
            }
        }

        ${props =>
            props.modalSlideOut &&
            css`
                animation: 0.7s modalSlideout;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
                
                @keyframes modalSlideout {
                    0% {
                        transform: translateX(0%);
                        visibility: visible;
                    }
                    100% {
                        transform: translateX(-100%);
                        pointer-events: none;
                        visibility: hidden;
                    }
                }
            `};
       

        @media (max-width: 1200px) {
            width: 50%;
        }
        @media (max-width: 834px) {
            bottom: 0;
            top: auto;
            height: 60vh;
            width: 100%;
            @keyframes slide {
                from {
                    transform: translateY(100%);
                }
                to {
                    transform: translateY(0%);
                }
            }
            @keyframes modalSlideout {
                0% {
                    transform: translateY(0%);
                }
                100% {
                    transform: translateY(100%);
                    pointer-events: none;
                }
            }
        }
        @media (max-width: 320px) {
            height: 65vh;
        }
    `

export default function ProductContainer(props){

    const inputEl = useRef(null);

    useEffect(() => {
        inputEl.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [props.activePinID])

    function handleBenefits(data){

        let benefitsLibrary = [
            { title : 'Design Benefits', data: data['Design Benefits'] }, 
            { title : 'Noise Reduction Benefits', data: data['Noise Reduction Benefits'] }, 
            { title : 'Structural Benefits', data: data['Structural Benefits'] }, 
            { title : 'Constructability Benefits', data: data['Constructability Benefits'] }, 
            { title : 'Safety Benefits', data: data['Safety Benefits'] }, 
            { title : 'Longevity Benefits', data: data['Longevity Benefits'] }, 
            { title : 'Efficiency Benefits', data: data['Efficiency Benefits'] }, 
        ]

        return benefitsLibrary.filter(x => x.data && x.data.indexOf("\n") !== 0).slice(0, 4);
    }



    //Airtable approach
    let airtablePins = props.airtablePins;

    return(
        <Modal modalSlideOut={props.modalSlideOut} ref={inputEl}>

            { airtablePins.map((pinData, key) => {
                let data = pinData['fields']
                if (props.activePinID === data['ID']){
                    let benefits = handleBenefits(data);
                    let relatedSolutions = airtablePins.filter( pin => pin.fields["Solution"] === data['Solution']);
                    // console.log("airtablePins ", airtablePins)
                    // console.log("relatedSolutions ", relatedSolutions)
                        return (
                            <Product 
                                key={data['ID'] + key} 
                                active={props.activePinID === data['ID']}
                                id={data['ID']} 
                                title={data['Title']}
                                description={data['Description']}
                                sector={data['Sector']}
                                solution={data['Solution']}
                                benefitOne={benefits[0]}
                                benefitTwo={benefits[1]}
                                benefitThree={benefits[2]}
                                benefitFour={benefits[3]}
                                linkToProduct={data['Link to product']}
                                projectPageFilter={data['Project Page Filter']}
                                photos={data['Reference Images']}
                                relatedSolutions={relatedSolutions}
                                setActivePinID={props.setActivePinID}
                                zoomToElementId={props.zoomToElementId}
                                handleProductClose={props.handleProductClose}
                                handleProductLightbox={props.handleProductLightbox}
                            />
                        )
                }
            })}



{/* 

            { pinData.map((data, key) => {

                if (props.activePinID === data['ID']){
                    let benefits = handleBenefits(data);
                    let relatedSolutions = pinData.filter( pin => pin["Solution"] === data['Solution']);
                        return (
                            <Product key={key} 
                                active={props.activePinID === data['ID']}
                                id={data['ID']} 
                                title={data['Title']}
                                description={data['Description']}
                                sector={data['Sector']}
                                solution={data['Solution']}
                                benefitOne={benefits[0]}
                                benefitTwo={benefits[1]}
                                benefitThree={benefits[2]}
                                benefitFour={benefits[3]}
                                linkToProduct={data['Link to product']}
                                projectPageFilter={data['Project Page Filter']}
                                projectPhotos={data['Reference Images']}
                                relatedSolutions={relatedSolutions}
                                setActivePinID={props.setActivePinID}
                                zoomToElementId={props.zoomToElementId}
                                handleProductClose={props.handleProductClose}
                                handleProductLightbox={props.handleProductLightbox}
                            />
                        )
                }
            })} */}

        </Modal>
    )
}