import styled, { css } from 'styled-components'
import Icon from './Icon'
import ZoomRange from './ZoomRange'

const ZoomBarWrapper = styled.div`
    position:absolute;
    z-index:1;
    top: 50%;
    transform: translateY(-50%) translateX(0%);
    right:0;

    @keyframes slidein {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    @media (max-width: 568px) {
        top: .5rem;
        right: .5rem;
        padding: .5rem;
        transform: translateY(0%);

        @keyframes slidein {
            from {
                transform: translateY(0%) translateX(100%);
            }
            to {
                transform: translateY(0%) translateX(0%);
            }
        }
        @keyframes slideout {
            from {
                transform: translateY(0%) translateX(0%);
                visibility: visible;
            }
            to {
                transform: translateY(0%) translateX(100%);
                pointer-events: none;
                visibility: hidden;
            }
        }
    }
`

const ZoomBarInner = styled.div`
    display:flex;
    flex-direction:column;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0px 51px 80px rgba(0, 0, 0, 0.07), 0px 21.3066px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 11.3915px 17.869px rgba(0, 0, 0, 0.0417275), 0px 6.38599px 10.0172px rgba(0, 0, 0, 0.035), 0px 3.39155px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 1.4113px 2.21381px rgba(0, 0, 0, 0.0196802);
    animation: 0.5s slidein;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    ${props =>
        props.activePinID &&
        css`
            animation: 0.8s slideout;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
            
            @keyframes slideout {
                from {
                    transform: translateX(0%);
                    opacity: 1;
                    visibility: visible;
                }
                to {
                    transform: translateX(100%);
                    opacity: 0;
                    pointer-events: none;
                    visibility: hidden;
                }
            }
        `};
`

const ZoomLabel = styled.span`
    text-align: center;
    color: #777;

    @media (max-width: 568px) {
        font-size: 1.6rem;
    }
    @media (max-width: 450px) {
        display: none;
    }
`

const ZoomBlock = styled.div`
    background-color:#eee; 
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        background: var(--grey);
    }

    @media (max-width: 568px) {
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        
        svg {
            width: 2rem;
            height: 2rem;
        }
    }
    @media (max-width: 450px) {
        width: 3.8rem;
        height: 3.8rem;
        &:first-of-type {
            margin-bottom: 1rem;
        }
    }
`

const ZoomMeterWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    position:relative;
    margin: 1rem 0;

    @media (max-width: 568px) {
        margin: .5rem 0;
    }
    @media (max-width: 450px) {
        display: none;
    }
`

const ZoomMeterColumn = styled.div`
    position:absolute;
    width:6px;
    height:100%;
    background:#eee;
    top:0;
    left:calc(50% - 3px);
`

const ZoomMeterMark = styled.div`
    position:relative;
    width:25px;
    height:2px;
    background:#eee;
    margin-bottom:25px;
    z-index:1;
    &:last-of-type {
        margin-bottom:0px;
    }
    ${props => 
      props.active &&
      css`
        background:#575757;
        height:3px;
      `
    }

    @media(max-width: 320px) {
        margin-bottom: 20px; 
    }
`

export default function ZoomBar(props){

    const handlePressZoomIn = e => {
        if(e.key === 'Enter') { 
            props.zoomIn()
        }
    }

    const handlePressZoomOut = e => {
        if(e.key === 'Enter') { 
            props.zoomOut()
        }
    }

    return (
        <ZoomBarWrapper activePinID={props.activePinID}>
            <ZoomBarInner activePinID={props.activePinID}>
                <ZoomLabel>ZOOM</ZoomLabel> 
                <ZoomBlock onClick={()=>props.zoomIn()} role="button" tabIndex={1} onKeyPress={handlePressZoomIn}>
                    <span className="sr-only">Zoom In</span>
                    <Icon type="Zoom In" />
                </ZoomBlock>
                <ZoomMeterWrapper>
                    <ZoomRange rangeLevel={props.rangeLevel} handleRangeChange={props.handleRangeChange} /> 
                </ZoomMeterWrapper>
                <ZoomBlock onClick={()=>props.zoomOut()} role="button" tabIndex={1} onKeyPress={handlePressZoomOut}>
                    <span className="sr-only">Zoom Out</span>
                    <Icon type="Zoom Out" />
                </ZoomBlock>
            </ZoomBarInner>
        </ZoomBarWrapper>
    )
}