import styled, { css } from 'styled-components'
import Icon from './Icon'

const ButtonWrapper = styled.div`
    display:flex;
    align-items:center;
    margin-right:1rem;
    padding:1rem 2rem;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }

    &:hover > div > svg > * {
        fill: white;
    }
    &:hover > div {
        color: white;
    }
    
    ${props =>
        props.solution === "All" &&
        css`
            background: #5b5b5b;
            color: #fff;
            &:hover {
                background: #5b5b5b;
            }
        `};
    ${props =>
        props.solution === "Noise Walls" && 
        css`
            background: var(--green);
            color: #fff;
            &:hover {
                background: var(--green);
            }
        `};
    ${props =>
        props.solution === "Retaining Walls" &&
        css`
            background: var(--lightBlue);
            color: #fff;
            &:hover {
                background: var(--lightBlue);
            }
        `};
    ${props =>
        props.solution === "Building Applications" &&
        css`
            background: var(--darkBlue);
            color: #fff;
            &:hover {
                background: var(--darkBlue);
            }
        `};

    ${props =>
        !props.active &&
        css`
            background: #f4f4f4;
            color: #575757;
        `};

    @media(max-width: 845px) {
        margin-right: 0;
        margin-bottom: 1rem;
        padding: 0.5rem 1rem;
        min-height: 5rem;
    }
`
const SolutionIcon = styled.div`
    margin-right: 1rem;
    position:relative;
    top: 2px;
    
    ${props =>
        props.solution === "All" &&
        css`
            display: none;
        `};
    ${props =>
        props.solution === "Noise Walls" && props.activeSolution === "All" &&
        css`
            svg > * {
                fill: var(--green)
            }
        `};
    ${props =>
        props.solution === "Retaining Walls" && props.activeSolution === "All" &&
        css`
            svg > * {
                fill: var(--lightBlue);
            }
        `};
    ${props =>
        props.solution === "Building Applications" && props.activeSolution === "All" &&
        css`
            svg > * {
                fill: var(--darkBlue);
            }
        `};
    
    ${props =>
        props.active &&
        css`
            svg > * {
                fill: #fff; 
            }
        `};

    // If the active solution is not "All," grey out all the inactive solution icons
    // (Active solution icon remains white)
    ${props => 
        props.activeSolution !== props.solution && props.activeSolution !== "All" &&
        css`
            svg > * {
                fill: #ACACAC;
            }
        `};

    @media(max-width: 845px) {
        width: 3rem;
        svg {
            width: 100%;
            min-width: 3rem;
        }
    }
    @media(max-width: 450px) {
        width: 2.2rem;
        svg {
            min-width: 2.2rem;
        }
    }
`
const ButtonTitle = styled.div`
    font-size: 2rem;

    @media(max-width: 1100px) {
        font-size: 1.8rem;
    }
    @media(max-width: 450px) {
        font-size: 1.6rem;
        line-height: 1.2;
    }
`

export default function FilterButton(props){

    const handlePress = e => {
        if(e.key === 'Enter') { 
            props.setActiveSolution(props.solution)
        }
    }

    return(
        <ButtonWrapper solution={props.solution} active={props.activeSolution === props.solution} onClick={() => props.setActiveSolution(props.solution)} role="button" tabIndex={1} onKeyPress={handlePress}>
           <SolutionIcon solution={props.solution} active={props.activeSolution === props.solution} activeSolution={props.activeSolution}> <Icon type={props.solution} /> </SolutionIcon>
           <ButtonTitle><span className="sr-only">Filter by </span>{props.solution}</ButtonTitle>
        </ButtonWrapper>
    )
} 