import styled, {css} from 'styled-components';
import Icon from './Icon';
import pinShadowImg from './../images/pin-shadow.png';


const PinWrapper = styled.div.attrs(props => ({
    style: {
        transform: `scale(${props.scaleFix})`,
    },
    }))`
    position:absolute;
    left: ${props => props.posX}%;
    top: ${props => props.posY}%;

    will-change:transform;
   
    transition: opacity .25s ease-out;
    transform-origin:bottom center;
    animation: 1.8s fade-in;
    animation-delay: .8s;
    animation-fill-mode: forwards;
    opacity: 0;

`

const PinContainer = styled.div`

    // will-change:transform;

    width: 83px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &::before {
        content:"";
        position:absolute;
        width:230%;
        height:230%;
        border-radius:50%;
        border: 2px solid #eee;
        pointer-events: none;
        opacity:0;
        // transition: opacity 0.5s ease-out .1s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.2s;
       
    }
    &::after {
        content: "";
        position:absolute;
        width:350%;
        height:350%;
        border-radius:50%;
        border: 2px solid #eee;
        pointer-events: none;
        opacity: 0;
        // transition: opacity 0.5s ease-out .2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.3s;

        
    }
    &:hover{
        // transform: scale(1.15);
         &::before {
            // opacity: 0;
            animation: 1.5s pulse;
            animation-iteration-count: infinite;
           
         }
         &::after {
            // opacity:1;
            animation: 1.5s outer-pulse;
            animation-iteration-count: infinite;
         }
    }

    // If pins are part of current active solution, but not selected, colorize them on hover
    ${props =>
        props.activeSolution && props.solution === "Retaining Walls" &&
        css`
            &:hover, &:focus {
                .pin-marker svg path {
                    fill: var(--lightBlue) !important;
                }
                &::before, &::after {
                    border: 1px solid var(--lightBlue);
                }
            }
    `};
    ${props =>
        props.activeSolution && props.solution === "Noise Walls" &&
        css`
            &:hover, &:focus {
                .pin-marker svg path {
                    fill: var(--green) !important;
                }
                &::before, &::after {
                    border: 1px solid var(--green);
                }
            }
    `};
    ${props =>
        props.activeSolution && props.solution === "Building Applications" &&
        css`
            &:hover, &:focus {
                .pin-marker svg path {
                    fill: var(--darkBlue) !important;
                }
                &::before, &::after {
                    border: 1px solid var(--darkBlue);
                }
            }
    `};

    // If pins are not part of the current active solution, colorize them on hover
    ${props =>
        !props.activeSolution && props.solution === "Retaining Walls" &&
        css`
            &:hover, &:focus {
                .pin-marker svg path {
                    fill: var(--lightBlue) !important;
                }
                .pin-icon svg path {
                    stroke: #fff;
                }
                &::before, &::after {
                    border: 1px solid var(--lightBlue);
                }
            }
    `};
    ${props =>
        !props.activeSolution && props.solution === "Noise Walls" &&
        css`
            &:hover, &:focus {
                .pin-marker svg path {
                    fill: var(--green) !important;
                }
                .pin-icon svg path {
                    stroke: #fff !important;
                }
                &::before, &::after {
                    border: 1px solid var(--green);
                }
            }
    `};
    ${props =>
        !props.activeSolution && props.solution === "Building Applications" &&
        css`
            &:hover, &:focus {
                .pin-marker svg path {
                    fill: var(--darkBlue) !important;
                }
                .pin-icon svg path {
                    stroke: #fff;
                }
                &::before, &::after {
                    border: 1px solid var(--darkBlue);
                }
            }
    `};

    // Overwrite solution icon colour with white on hover of unselected pin
    ${props => props.activeSolution && !props.active && props.activePinID !== null && css`
            &:hover, &:focus {
                .pin-icon svg path {
                    stroke: #fff !important;
                }
            }
        `}


    ${props =>
    props.active &&
    css`
        pointer-events:none;
        cursor: auto;

        &::before {
        //  content:"";
        //  position:absolute;
            width:250%;
            height:250%;
        //  border-radius:50%;
            border: 2px solid var(--activeColor);
        //  pointer-events: none;
            transform: scale(0);
            opacity:1;
            animation: 0.8s radiate;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
            @media(max-width: 834px) {
                width: 200%;
                height: 200%;
            }
        }
        &::after {
        //  content: "";
        //  position:absolute;
            width:800%;
            height:800%;
        //  border-radius:50%;
            border: 1px solid var(--activeColor);
            opacity: 0;
            transform: scale(0);
        //  pointer-events: none;
            animation: 1s radiate-fade-in;
            animation-delay: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(.25,.75,.5,1.2);


            @media(max-width: 834px) {
                width: 400%;
                height: 400%;
            }
        }
    `};

    ${props =>
    props.modalSlideOut &&
    css`
        &::before {
            transform: scale(1);
            animation: 0.8s unradiate;
            animation-fill-mode: forwards;

            @keyframes unradiate {
                from {
                    transform: scale(1);
                }
                to {
                    transform: scale(0);
                }
            }
        }
        &::after {
            transform: scale(0);
            animation: 0.8s unradiate;
            animation-fill-mode: forwards;

            @keyframes unradiate {
                from {
                    transform: scale(1);
                }
                to {
                    transform: scale(0);
                }
            }
        }
    `};
`

const PinShadow = styled.div`
    position:absolute;
    z-index:-1;
    left: -5px;
    top: 15px;
    // will-change:transform;
`

const PinMarker = styled.div`
    position:absolute;
    // will-change:transform;
    svg { 
        path {
            fill:#AFAFAF;
            transition: fill 0.1s ease;

            // If no specific pin is active, colour in all the pins
            ${props =>
                props.solution === "Noise Walls" && props.activeSolution && !props.activePinID && 
                css`
                    fill: var(--green);
                `};
            ${props =>
                props.solution === "Retaining Walls" && props.activeSolution && !props.activePinID &&
                css`
                    fill: var(--lightBlue);
                `};
            ${props =>
                props.solution === "Building Applications" && props.activeSolution && !props.activePinID &&
                css`
                    fill: var(--darkBlue);
                `};
            
            // If a specific pin is active, colour it in
            ${props =>
                props.solution === "Noise Walls" && props.active && 
                css`
                    fill: var(--green);
                `};
            ${props =>
                props.solution === "Retaining Walls" && props.active && 
                css`
                    fill: var(--lightBlue);
                `};
            ${props =>
                props.solution === "Building Applications" && props.active && 
                css`
                    fill: var(--darkBlue);
                `};

            // If a specific pin is active, make the other inactive pins grey 
            ${props =>
                props.activeSolution && !props.active && props.activePinID &&
                css`
                    fill: #afafaf;
                `};
        }
    }
    
`

const PinIcon = styled.div`
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    z-index:1;
    left: 0px;
    top: -12px;
    svg {
        > * {
                fill: #fff;
            ${props => props.activeSolution && css`
                fill: #fff;
            `}
            ${props => props.active && css`
                fill: #fff;
            `}
            ${props => props.activeSolution && !props.active && props.activePinID !== null && css`
                fill: #DEDEDE;
            `}
        }
    } 

`

const PinZoomMark = styled.div`
    // will-change:transform;  
    position:absolute;
    right: 30vw;
    top:50%;
    height:0;
    width:0;
    @media (max-width: 1200px) {
        right: 35vw;
    }
    @media (max-width: 834px) {
        right: 50%;
        top: 40vh;
    }
`


export default function Pin(props){

    // counter (x) the parent scale (y) so as to return to 1 
    // yx = 1, or x = 1/y 
    let scaleFix = 1 / props.scaleBase;

    let id = 'a' + props.id;

    const handlePress = e => {
        if(e.key === 'Enter') { 
            props.handlePinClick(props.id, props.solution)
        }
    }

    return(
        <PinWrapper 
            onClick={()=>props.handlePinClick(props.id, props.solution)} 
            active={props.active} 
            activeSolution={props.activeSolution} 
            posY={props.posY} 
            posX={props.posX} 
            solution={props.solution} 
            scaleFix={scaleFix}
            
            >
            <PinContainer active={props.active} activeSolution={props.activeSolution} solution={props.solution} modalSlideOut={props.modalSlideOut} role="button" tabIndex={ props.activePinID === null ? 2 : -1} onKeyPress={handlePress}>
                <PinShadow> <img src={pinShadowImg} role="presentation"/> </PinShadow>
                <PinMarker solution={props.solution} activeSolution={props.activeSolution} active={props.active} activePinID={props.activePinID} className="pin-marker">
                    <svg width="63" height="82" viewBox="0 0 63 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.97176 47.654L5.81642 47.4319L5.83368 47.4746C2.80167 42.8313 1.09053 37.4649 0.880394 31.9402C0.670261 26.4155 1.96891 20.937 4.63962 16.0812C7.31033 11.2255 11.2543 7.17223 16.0564 4.3481C20.8585 1.52397 26.341 0.0334473 31.9269 0.0334473C37.5128 0.0334473 42.9953 1.52397 47.7974 4.3481C52.5995 7.17223 56.5435 11.2255 59.2142 16.0812C61.8849 20.937 63.1835 26.4155 62.9734 31.9402C62.7633 37.4649 61.0521 42.8313 58.0201 47.4746L57.882 47.6796C57.6993 47.9131 57.5166 48.1666 57.328 48.4283C57.3028 48.4633 57.2775 48.4985 57.252 48.5338L34.8137 80.4881C34.4901 80.948 34.059 81.3237 33.5571 81.5831C33.0552 81.8425 32.4974 81.978 31.9312 81.978C31.365 81.978 30.8072 81.8425 30.3053 81.5831C29.8034 81.3237 29.3723 80.948 29.0487 80.4881L6.55861 48.5081C6.44934 48.3436 6.34007 48.1867 6.23224 48.032C6.14419 47.9056 6.0571 47.7807 5.97176 47.654Z" fill="#0098C8"/>
                    </svg>
                    {/* <svg width="125" height="164" viewBox="0 0 125 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2272 95.2358L9.91651 94.7916L9.95104 94.877C3.88672 85.5911 0.464281 74.8588 0.043994 63.81C-0.376293 52.7613 2.22112 41.8047 7.56281 32.0938C12.9045 22.3829 20.7929 14.2768 30.3975 8.62882C40.0021 2.98087 50.9677 0 62.14 0C73.3122 0 84.2779 2.98087 93.8825 8.62882C103.487 14.2768 111.376 22.3829 116.717 32.0938C122.059 41.8047 124.656 52.7613 124.236 63.81C123.816 74.8588 120.393 85.5911 114.329 94.877L114.053 95.287C113.687 95.7541 113.322 96.2609 112.945 96.7842C112.894 96.8543 112.844 96.9247 112.793 96.9953L67.9138 160.9C67.2667 161.82 66.4045 162.571 65.4006 163.09C64.3968 163.609 63.2811 163.88 62.1486 163.88C61.0162 163.88 59.9005 163.609 58.8966 163.09C57.8928 162.571 57.0306 161.82 56.3834 160.9L11.401 96.944C11.1824 96.6149 10.9639 96.3013 10.7482 95.9918C10.5721 95.7391 10.3979 95.4892 10.2272 95.2358Z" fill="#0098C8"/>
                    </svg> */}
                </PinMarker>
                <PinIcon active={props.active} activeSolution={props.activeSolution} activePinID={props.activePinID} className="pin-icon">
                    <Icon type={props.solution} />
                </PinIcon>
            </PinContainer>
            <PinZoomMark id={id}/>
        </PinWrapper>
    )
}