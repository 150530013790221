import styled from "styled-components";
import Solution from '../components/Solution';
import cityscapeBG from './../images/cityscape-preview.png'
import allSolutionsImg from './../images/sector-all-solutions.png';
import noiseWallsImg from './../images/sector-noise-walls.png';
import retainingWallsImg from './../images/sector-retaining-walls.png';
import buildingApplicationsImg from './../images/sector-building-applications.png';

const Container = styled.div`
    position:relative;
    left:0;
    top:0;
    height:100%;
    overflow: hidden; 
    z-index:2;
    @media(max-width:1000px){
        min-height: 700px; // allow content to overflow on mobile if viewing horizontally?
    }
    @media (max-height:890px) and (min-width:769px){
        overflow-y:scroll;  
    }
`
const BackgroundImageContainer = styled.div`
    position: absolute;
    top: 30%;
    left: 40%;
    bottom: auto;
    right: auto;
    margin-right: -30%;
    transform: translate(-30%, -30%);
    z-index: -1;
    img {
        width: 120%;
        min-width: 150rem;
    }
    animation: 1.2s fade;

    @keyframes fade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`
const GradientOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0.6), rgba(255,255,255,0), rgba(255,255,255,0));
`
const ContentContainer = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 2%;

    @media(max-width: 1440px) {
        margin-top: 0;
    }
    @media(max-width:1024px) {
        margin: 0 5% auto;
    }
    @media(max-width:720px) {
        top: 0;
        transform: translateY(0);
        padding-top: 2rem;
    }
    @media (max-height:890px) and (min-width:769px){
        top:20px;
        transform:none;
    }
`
const TextContainer = styled.div`
    width: 55%;
    max-width: 1005px;
    margin: 0 2rem;
    opacity: 0;
    transform: translateY(3rem);
    animation: 1s fade-up;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    @keyframes fade-up {
        from {
            opacity: 0;
            transform: translateY(3rem);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 1208px) {
        width: 80%;
    }
    @media(max-width:1024px) {
        margin: 0;
    }
    @media (max-width: 720px) {
        width: 100%;
    }
    @media(max-width: 600px) {
        width: auto;
    }
`
const Headline = styled.h1`
    &&& {
        color: var(--green);
        font-size: var(--cityscape-h1);
        letter-spacing:0;
        line-height:120%;
        margin-bottom:3rem;
        
        &[style] {
            @media(max-width: 1440px) {
                font-size: 5rem !important;
            }
            @media(max-width: 768px) {
                font-size: 4.4rem !important;
                line-height: 1 !important;
            }
            @media(max-width: 415px) {
                font-size: 3rem !important;
            } 
            @media(max-width: 375px) {
                font-size: 2.5rem !important;
            }
            @media(max-width: 320px) {
                font-size: 2rem !important;
            }
        }
    
        @media(max-width:1280px) {
            margin-bottom: 2rem;
            line-height:110%;
        }
        @media(max-width:1100px) {
            margin-top:0;
        }
    }
`
const IntroDesc = styled.p`
    &&& {
        font-size: var(--cityscape-p);
        font-weight: 500;
        line-height: 1.62;
        @media(max-width:320px) {
            line-height: 1.3;
        }
    }
`
const SolutionsGroup = styled.div`
    &&& {
        margin: 5rem 2rem 0 2rem;
        opacity: 0;
        animation: 2s fade-in;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
    
        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    
        @media(max-width: 1024px) {
            margin: 2rem 0;
        }
    }
    
`
const IntroPrompt = styled.p`
    &&& {
        font-weight: 700;
        font-size:2rem;
        margin: 0 0 3rem;
        @media(max-width:1024px) {
            margin: 0 0 1rem;
        }
        @media(max-width: 768px) {
            margin-bottom: 1.5rem !important;
            font-size: 1.6rem;
            line-height: 1.2;
        }
        @media(max-width:375px) {
            font-size: 1.4rem;
        }
    }
`
const SolutionsContainer = styled.div`
    display: flex;

    @media(max-width: 720px) {
        flex-wrap: wrap;
        width: 80%;
    }
    @media(max-width: 600px) {
        width: 100%;
    }
`

export default function IntroContainer(props){
    return(
        <Container>
            <BackgroundImageContainer>
                <GradientOverlay></GradientOverlay>
                <img src={cityscapeBG} alt="Illustration of a city from above" className="intro-slide__background"/>
            </BackgroundImageContainer>
            <ContentContainer>
                <TextContainer>
                    <Headline>Welcome to the Durisol cityscape</Headline>
                    <IntroDesc>A place that connects communities safely using sustainable infrastructure. A city that enables land revitalization, encourages community engagement, and protects the health & well-being of residents and businesses.</IntroDesc>
                </TextContainer>
                <SolutionsGroup>
                    <IntroPrompt>Click on a solution to begin exploring our city interactive</IntroPrompt>
                    <SolutionsContainer>
                        <Solution solution="All" imgSrc={allSolutionsImg} imgAlt="Illustration of city" setActiveSolution={props.setActiveSolution}  />
                        <Solution solution="Noise Walls" imgSrc={noiseWallsImg} imgAlt="Noise Walls" setActiveSolution={props.setActiveSolution} />
                        <Solution solution="Retaining Walls" imgSrc={retainingWallsImg} imgAlt="Retaining Walls" setActiveSolution={props.setActiveSolution}  />
                        <Solution solution="Building Applications" imgSrc={buildingApplicationsImg} imgAlt="Building Walls" setActiveSolution={props.setActiveSolution}  />
                    </SolutionsContainer>
                </SolutionsGroup>
            </ContentContainer>
        </Container>
    )
}  